import React from 'react'
import Layout from '../layouts/layout'

const CateringSuccessPage = () => (
	<Layout title="Catering Request Sent">
		<div>
			<h1>Catering Request Sent</h1>

			<p>
				Your catering request has been sent. If you need to follow up,
				feel free to give us a call at{' '}
				<a href="tel:+17602957993">
					<span className="sr-only">
						Call our catering office at:{' '}
					</span>
					(760) 295-7993
				</a>
				.
			</p>
		</div>
	</Layout>
)

export default CateringSuccessPage
